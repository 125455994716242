<template>
    <b-container id="termos-de-uso" class="height-full" fluid>
        <b-row style="padding-top: 50px">
            <b-col class="text-right">
                <b-img-lazy style="height: 76px"
                            alt="ZZVendas" fluid
                            :src=" require( '../../assets/logos/zzvendas.png') "
                ></b-img-lazy>
            </b-col>
            <b-col class="text-left d-flex align-items-center">
                <h1>Vendas</h1>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div id="conteudo">
                    <h2>TERMOS E CONDIÇÕES GERAIS DO <br/> PROJETO CANAL REVENDEDORA AREZZO&CO</h2>
                    <p>
                        <strong>Leia com atenção os termos e condições gerais, pois constam deles todas as informações importantes sobre os seus direitos e obrigações legais, incluindo limitações, exclusões e um foro para dirimir eventuais conflitos relativos ao presente Termo.</strong><br/>
                        Em que são Partes, como <strong>Franqueadora</strong>:<br/>
                        <strong>AREZZO INDÚSTRIA E COMÉRCIO S.A.</strong>, sociedade anônima aberta, inscrita no CNPJ/MF sob o n.° 16.590.234/0001-76, sediada na Rua Fernandes Tourinho, n.° 147, sala 402, bairro Funcionários, no Município de Belo Horizonte, MG, CEP 30.112-000, neste ato presentada na forma de seu Estatuto Social.<br/>
                        Como <strong>Franqueada</strong>:<br/>
                        <strong>[Denominação Social]</strong>, [natureza jurídica], inscrita no CNPJ/MF sob o n.° [número], sediada na [endereço], CEP [número], neste ato presentada por seu [sócio administrador ou titular], Sr.(a) [Nome]. <br/>
                        E, como <strong>Promotora</strong>:<br/>
                        <strong>[Nome Completo]</strong>, [qualificação], com cédula de identidade n.° [número], inscrito(a) no CPF/MF sob o n.° [número], residente e domiciliado(a) no [endereço], CEP [número].<br/>
                        Individualmente também designadas “Partes” e, em conjunto, “Partes”.<br/>
                        <strong>DEFINIÇÕES</strong><br/>
                        Para evitar dúvidas quanto à interpretação das palavras utilizadas com frequência neste Termos e Condições Gerais do Projeto Canal Revendedora Arezzo & CO (o “Termo”), seguem enunciados com sentidos que lhes é atribuído:<br/>
                        <strong>a)</strong> Área da Promotora (ZZNET): página exclusiva e individual da Promotora, por meio da qual pode acompanhar as vendas, bonificações e campanhas da Arezzo, com acesso mediante utilização de login específico, preenchimento dos campos individuais, pessoais e intransferíveis, de usuário e de senha;<br/>
                        <strong>b)</strong> Cliente: É o internauta visitante que realiza pedidos e adquire produtos através de plataformas e/ou site;<br/>
                        <strong>c)</strong> Cookie: pequeno arquivo armazenado nos browsers, possibilitando a identificação de usuários e o direcionamento de documentos de acordo com parâmetros preestabelecidos;<br/>
                        <strong>d)</strong> Home Page: significa a página inicial e de entrada de um determinado Site;<br/>
                        <strong>e)</strong> HTML: significa a abreviação de Hyper Text Markup Language, o padrão de linguagem para desenvolvimento das páginas que compõem um Site;<br/>
                        <strong>f)</strong> Internet: significa a rede mundial aberta de computadores interligados entre si;<br/>
                        <strong>g)</strong> Internauta Visitante: qualquer indivíduo que esteja acessando o site, blog, perfil de rede social ou outras páginas ou propriedades disponíveis na Internet relativas à Promotora, ou o site da Franqueadora;<br/>
                        <strong>h)</strong> Janela de Remuneração: período compreendido entre a venda do produto e a efetiva remuneração da Bonificação;<br/>
                        <strong>i)</strong> Transação Gerada (occurrence/ocorrência): Pedido gerado independente do status (pendente, aprovado cancelado);<br/>
                        <strong>j)</strong> Transação Pendente: Pedido aferido pela plataforma utilizada para venda de produtos de estoque da Franqueadora (Web) ou estoque da Franqueada;<br/>
                        <strong>k)</strong> Transação Confirmada: Pedido avaliado e aprovado pela Franqueada ou Franqueadora, a depender da modalidade de venda, que não tenha se tornado uma transação cancelada.<br/>
                        <strong>l)</strong> Transação Cancelada: Pedido recusado ou pedido que foi cancelado pela administradora de cartão de crédito ou, ainda, transações inicialmente confirmadas, mas que tiveram troca/devolução por parte do cliente e/ou pedidos cancelados pelo próprio cliente.<br/>
                        Se, no entanto, durante a vigência deste Termo, ocorrer a prática de atos identificados por outros termos de linguagem técnica, inclusive aqueles grafados em idioma estrangeiro e não definidos acima, estes termos deverão ser compreendidos e interpretados em consonância com o conceito internacionalmente consagrado, no que não conflitarem com as definições aqui convencionadas.<br/>
                        <br/><br/><br/>

                        <strong>Considerando que</strong>:<br/>
                        <strong>a) </strong>A Franqueada mantém Contrato de Franquia vigente com a Franqueadora Arezzo Indústria e Comércio S.A., sociedade anônima aberta, inscrita no CNPJ/MF sob o n.º 16.590.234/0001-76 (a “Franqueadora”), para exploração de Unidade Franqueada da marca Arezzo (a “Unidade Franqueada”);<br/>
                        <strong>b)</strong> A Franqueada está participando do Projeto Canal Revendedora (o “Projeto”), implementado em um grupo seleto de franqueados da rede de franquias Arezzo; e<br/>
                        <strong>c)</strong> O Projeto possibilita que pessoas físicas interessadas, voluntariamente, divulguem os produtos do estoque da Unidade Franqueada e do e-commerce da Franqueadora diretamente aos consumidores, respeitando as regras e condições deste Termo, termos de uso e política de privacidade, atuando como Promotor(a) Comercial de venda, sem qualquer vínculo trabalhista.<br/>
                        Por este instrumento, as Partes concluem o Termo e Condições Gerais Do Projeto Canal Revendedora Arezzo&Co, que se regerá pelas cláusulas a seguir:<br/>

                        <strong>Da definição</strong> <br/>
                        <strong>1.</strong> O Projeto Canal Revendedor é o canal de vendas complementar, que consiste na possibilidade de venda de produtos, por intermédio de uma promotora comercial (em diante apenas “Promotora”), que não detém relação trabalhista com a Franqueada ou Franqueadora, através de aplicativo, em duas modalidades: (i) utilizando produtos do estoque da Unidade Franqueada; ou (ii) utilizando produtos do estoque da Franqueadora. <br/>
                        <strong>2.</strong> Promotora adere ao Projeto Canal Revendedora (o “Projeto”), adotando integralmente os termos e condições ora estipulados, passando a compor o cadastro e a base de dados da Arezzo&Co, atuando como Promotora Comercial, divulgadora dos produtos da Franqueada e Franqueadora.<br/>
                        <strong>2.2.</strong> Na modalidade de venda em que a Promotora utiliza o estoque da própria Unidade Franqueada vinculada, a Franqueada pagará à Promotora o percentual mínimo de 5% (cinco por cento) sobre o valor bruto da compra, excluindo os valores de frete, embalagem para presente ou outros serviços acessórios, mediante Recibo de Pagamento Autônomo (RPA); <br/>
                        <strong>2.3.</strong> Na modalidade de venda em que a Promotora utiliza o estoque da Franqueadora, a Franqueada pagará à Promotora o percentual mínimo de 5% (cinco por cento) sobre o valor bruto da compra, excluindo os valores de frete, embalagem para presente ou outros serviços acessórios, mediante Recibo de Pagamento Autônomo (RPA).<br/>

                        <strong>Do cadastramento</strong> <br/>
                        <strong>3.</strong> Para se tornar uma Promotora Comercial será necessário cadastramento na Plataforma de Cadastro da Revendedora, disponível no sistema ZZNet, preenchendo o formulário de cadastramento, devendo obrigatoriamente declarar a ciência e concordar com as condições gerais do programa e com as demais políticas e obrigações integrantes do presente Termo.<br/>
                        <strong>3.1.</strong> Uma vez cadastrada, a Promotora deverá seguir os procedimentos de acesso, informando os dados cadastrais que vierem a ser solicitados e que se façam necessários para que a Promotora possa acessar e ter acesso aos benefícios do Programa, em sua integralidade.<br/>
                        <strong>3.1.1.</strong> A Promotora consente de forma individual, clara e específica, em informar os dados cadastrais necessários para que seja possível sua manutenção dentro do Programa, para acesso pelos anunciantes e para pagamento das remunerações que lhe forem aplicáveis. Serão coletadas informações como: nome, documento de identificação, e-mail, data de nascimento, endereço, número de telefone, endereço de IP, tipo do navegador e idioma, informações de cookie, dados para pagamento, dentre outras que se fizerem necessárias.<br/>
                        <strong>3.1.2.</strong> A Franqueadora e Franqueada comprometem-se com a coleta e com tratamento dos dados coletados, obedecendo aos princípios da boa-fé, finalidade, adequação, necessidade, livre acesso, qualidade dos dados, transparência, segurança, prevenção, não discriminação bem como responsabilização e prestação de contas, comprometendo-se, ainda, a somente solicitar e tratar dados da Promotora que sejam correlacionados e essenciais ao Programa.<br/>
                        <strong>3.2.</strong> O Site Arezzo poderá utilizar cookies para tornar a experiência do Usuário mais eficiente, podendo armazenar cookies no seu dispositivo se eles forem estritamente necessários para a operação e, conforme a finalidade dos cookies, será facultado à Promotora conceder sua permissão.<br/>
                        <strong>3.2.1.</strong> A Promotora reconhece que a Franqueadora e a Franqueada poderão, em conjunto ou isoladamente, solicitar-lhe a comprovação documental de todas as informações prestadas.<br/>
                        <strong>3.3.</strong> Embora a Franqueadora e Franqueada não tenham qualquer responsabilidade sobre o conteúdo do site, blog, perfil de rede social ou outras propriedades relativas à Promotora, as primeiras se reservam o direito de, a qualquer momento, em conjunto ou isoladamente, proceder ao cancelamento e a exclusão da Promotora do programa, caso entenda, por critérios próprios, que sua manutenção não é compatível com a imagem da marca ou, ainda, caso verificada a promoção ou existência de conteúdos ilícitos ou contrários aos seus valores, que promova a violência de qualquer tipo, a discriminação de qualquer tipo, atividades ilegais como tráfico de drogas, terrorismo, racismo, violência, discriminação ou que, de qualquer outra forma, viole ou contribua para a violação à proteção de direitos de propriedade intelectual, pirataria de software etc., ou que adote comportamento fraudulento ou contrário à boa-fé contratual.<br/>
                        <strong>3.4.</strong> As Partes consignam que, caso a Franqueadora venha a sofrer qualquer dano decorrente de ação judicial ou administrativa em que a responsabilidade seja direta ou indiretamente da Promotora, deverá esta ressarcir todo e qualquer prejuízo.<br/>
                        <strong>3.4.1.</strong> Ciente de eventual ação judicial ou extrajudicial, a Promotora, desde já, autoriza e deverá aceitar eventual denunciação à lide, bem como providenciar a exclusão da Franqueadora e Franqueada, sem prejuízo das indenizações, reembolsos e perdas e danos devidos.<br/>
                        <strong>4.</strong> A comunicação efetuada pela Promotora, dentro dos limites deste Termo, obrigatoriamente, deverá se atentar para que:<br/>
                        <strong>a)</strong> não tenham caráter ofensivo, sejam falsos, inexatos, desatualizados ou que possam induzir a erro;<br/>
                        <strong>b)</strong> não caracterizem publicidade ou propaganda de concorrentes ou enganosa;<br/>
                        <strong>c)</strong> não constituam violação dos direitos de propriedade intelectual, ou caracterizem invasão de privacidade /intimidade da marca e sua titular ou de terceiros;<br/>
                        <strong>d)</strong> não violem qualquer cláusula deste instrumento, qualquer lei ou regulamentação em vigor, bem como seja contrário a moral e aos bons costumes; e<br/>
                        <strong>e)</strong> não sejam contrários aos valores da marca, ou que possa a vir prejudicá-la.<br/>
                        4.1. A Franqueadora reserva-se ao direito de remover quaisquer materiais, total ou parcialmente, a qualquer tempo, a seu exclusivo critério, sem aviso prévio da Promotora e Franqueada.<br/>
                        4.2. Em nenhum caso, a Franqueadora e a Franqueada serão responsabilizadas pela ocorrência de um erro técnico cometido pela Promotora durante o compartilhamento de seu "link", como por exemplo, compartilhamento de link errado, bem como por eventuais falhas relativas a conectividade, a internet, e problemas técnicos enfrentados pela Promotora.<br/>

                        <strong>Remuneração</strong><br/>
                        <strong>5.</strong> A Promotora terá direito ao recebimento de Bonificação correspondente ao percentual sobre o valor líquido recebido pela Franqueada em decorrência de cada transação efetivada por meio do seu link único, excluindo-se os valores de frete, embalagem para presente ou outros serviços acessórios, (a “Bonificação”) mediante Recibo de Pagamento Autônomo (RPA).<br/>
                        <strong>5.1.</strong> Na modalidade de venda em que a Promotora utiliza o estoque da própria Unidade Franqueada vinculada, a Franqueada pagará à Promotora o percentual mínimo de 5% (cinco por cento) sobre o valor bruto da compra, excluindo os valores de frete, embalagem para presente ou outros serviços acessórios, mediante Recibo de Pagamento Autônomo (RPA), sem qualquer responsabilidade da Franqueadora sob o pagamento, a que título for. <br/>
                        <strong>5.2.</strong> Na modalidade de venda em que a Promotora utiliza o estoque da Franqueadora, em contrapartida, a Franqueada pagará à Promotora o percentual mínimo de 5% (cinco por cento) sobre o valor bruto da compra, excluindo os valores de frete, embalagem para presente ou outros serviços acessórios, mediante Recibo de Pagamento Autônomo (RPA), sem qualquer responsabilidade da Franqueadora sob o pagamento, a que título for.<br/>
                        <strong>5.3.</strong> Independentemente da modalidade de venda, caberá à Promotora seu cadastro perante enquanto profissional autônoma perante o órgão público responsável, e o recolhimento dos impostos relativos à execução da sua atividade, tais como Imposto Sobre Serviços (ISS) que incidirá no valor da Bonificação. <br/>
                        <strong>6.</strong> Independentemente das circunstâncias, não serão computadas transações canceladas para efeitos de Bonificação.<br/>
                        <strong>6.1.</strong> Considera-se que a transação foi efetivada para a Promotora, quando essa for finalizada no link único e confirmada no aplicativo.<br/>
                        <strong>6.2.</strong> Para usufruir da Bonificação, a Promotora deverá:<br/>
                        <strong>a)</strong> Estar com o cadastro completo, atualizado e correto no que tange aos dados exigidos, a exemplo do nome completo, CPF, RG, PIS (programa de integração Social), e-mail, celular, data de nascimento e informações bancárias, inclusive, número de conta corrente ou poupança em seu nome, válida e ativa; e<br/>
                        <strong>b)</strong> Acumular o valor mínimo mensal igual ou superior a R$100,00 (cem reais), hipótese na qual será elegível para recebimento da Bonificação. Caso o valor acumulado seja inferior, este será acumulado e pago no mês subsequente em que atingido o valor mínimo.<br/>
                        <strong>b.1)</strong> Na hipótese da Promotora encerrar este Termo e possuir créditos elegíveis para pagamento da Bonificação, este será pago mesmo que o valor seja inferior ao valor mínimo de R$ 100,00 (cem reais), no prazo de 90 (noventa) dias após formalizada a desistência.<br/>
                        <strong>6.3.</strong> A Bonificação será paga uma vez por mês e será efetuada em até 45 dias da data de entrega do pedido.<br/>
                        <strong>6.4.</strong> Não serão considerados no cômputo das transações, para efeitos do cálculo da remuneração do espaço virtual cedido para a Promotora, aquelas consideradas como fraudulentas.<br/>
                        <strong>6.4.1.</strong> Caso uma venda considerada fraudulenta ou cancelada gere Bonificação e esta seja creditada à Promotora, esta obriga-se a restitui-la em até 10 (dez) dias contados a partir do recebimento de simples comunicação por parte da Franqueadora ou Franqueada. <br/>
                        <strong>6.4.1.1.</strong> Não obstante, a Franqueadora e a Franqueada ficam, de antemão, autorizadas a descontar este valor das bonificações futuras a serem pagas à Promotora. <br/>
                        <strong>6.5</strong>. A Franqueadora poderá modificar o modo de pagamento, bem como modificar os termos deste Acordo, mediante simples comunicação prévia à Promotora.<br/>

                        <strong>Do uso de imagens e depoimentos</strong><br/>
                        <strong>7. A</strong> Promotora autoriza expressamente a utilização de sua imagem, nome, marca, logotipo e de seus depoimentos, para veiculação e utilização da Franqueadora e Franqueada para fins comerciais, durante todo o prazo em que estiver vigente o presente instrumento.<br/>
                        <strong>7.1.</strong> A Promotora declara ciência de que referida autorização de uso de imagens e depoimentos repassados, não lhe dará o direito de receber nenhum valor adicional.<br/>

                        <strong>Da licença</strong><br/>
                        <strong>8.</strong> Todos os direitos intelectuais e industriais, sobre os bens de propriedade, autorizados ou licenciados da Franqueadora, como Sites, desenvolvimento, software, hardware, domínio, marcas, logomarcas, emblemas, logotipos, desenhos, industriais, estrutura, conteúdos, informação, aplicativos, sinais distintivos, produtos, entre outros, são de propriedade exclusiva da desta, e, em nenhuma hipótese, se entenderá que a Promotora tem algum tipo de direito sobre esses, independentemente da licença de uso dos aplicativos, plataformas, sites que a Franqueadora colocar à disposição da Promotora para fins de divulgação dos produtos, durante a vigência deste instrumento.<br/>
                        <strong>9.</strong> A Promotora não poderá contestar, ou de qualquer forma prejudicar, direta ou indiretamente, a validade da marca, obrigando-se a jamais discutir o direito de propriedade e de uso de tal.<br/>

                        <strong>Da ausência de vínculo trabalhista</strong><br/>
                        <strong>10.</strong> Este Termo não cria qualquer forma de vínculo empregatício, não importando em qualquer forma de subordinação hierárquica ou dependência econômica entre as partes.<br/>
                        <strong>11.</strong> As Partes estabelecem expressamente que de forma alguma é exigida carga horária específica, tendo a Promotora a total liberalidade para se dedicar ou não ao fomento das vendas dos produtos.<br/>

                        <strong>Da modificação</strong><br/>
                        <strong>12.</strong> A Franqueadora poderá, a qualquer momento, modificar os termos e condições deste instrumento, sem prévia anuência da Promotora, notificando-a acerca das modificações.<br/>
                        <strong>12.1.</strong> Caso a Promotora esteja de acordo e não aceite as alterações realizadas, restará extinto o vínculo contratual, mediante sua manifestação expressa.<br/>
                        <strong>12.2.</strong> A ausência de manifestação expressa acerca dos novos termos e condições publicadas pela Franqueadora implicará no aceite tácito dessas condições, que independentemente aplicar-se-ão conforme a sua publicação.<br/>

                        <strong>Da limitação de responsabilidade</strong><br/>
                        <strong>13.</strong> A Franqueadora não garantirá o acesso e uso continuado ou ininterrupto do seu site, bem como dos aplicativos e extensões envolvidos no programa à Promotora, haja vista que o sistema pode eventualmente não estar disponível devido a dificuldades técnicas ou falhas de rede ou de Internet, nos links ou aplicativos, ou, por qualquer outra circunstância alheia à vontade da Franqueadora.<br/>
                        <strong>14.</strong> A Promotora ou seus usuários não poderão imputar responsabilidade alguma à Franqueadora, nem exigir ressarcimento algum, em virtude de prejuízos resultantes das dificuldades mencionadas na Cláusula anterior, bem como por danos ou perdas indiretos, especiais ou consequentes, que surjam em conexão com o presente Termo, inclusive se ditas falhas afetarem os valores a serem pagos.<br/>
                        <strong>15.</strong> Nada neste instrumento deverá ser interpretado como uma promessa de resultado ou de recebimento de qualquer forma de remuneração por parte da Franqueadora ou Franqueada para com a Promotora, responsabilizando-se essa última, exclusivamente, pelos frutos e pelas vendas por meio do seu link único.<br/>
                        <strong>16.</strong> A Franqueadora não se responsabiliza e não tem qualquer relação com a atividade da Promotora e com os conteúdos promovidos por essa, respondendo a Promotora integral e exclusivamente pela sua atividade.<br/>

                        <strong>Da aceitação dos termos e condições</strong><br/>
                        <strong>17.</strong> Durante o processo de cadastramento da Promotora, ao pressionar o botão "aceitar", ou expressão equivalente, abaixo do Termo constante da Plataforma, as Partes reconhecem expressamente que a Promotora leu o presente Termo e concordou com todos seus termos e condições. Considera-se ainda, que tenha avaliado de forma livre e independente tais condições e que sua intenção de se tornar uma Promotora não está relacionada com alguma outra manifestação, garantia, ou declaração que não sejam as estabelecidas no presente Termo.<br/>

                        <strong>Da privacidade da informação</strong><br/>
                        <strong>18.</strong> Uma vez registrada a Promotora no programa, tendo aceitado os termos do presente Termo, cadastrando com seus dados e aprovado o cadastro pela Franqueadora, essa fará tudo o que estiver em seu alcance para proteger a privacidade das informações fornecidas. <br/>
                        <strong>18.1.</strong> Caso a Promotora não queira mais receber comunicados diretos da Franqueadora, poderá clicar na opção descadastrar, contida em todos os comunicados enviados pela Franqueadora.<br/>
                        <strong>18.2.</strong> Em virtude de ordem judicial ou administrativa, a Franqueadora e Franqueada poderão ser compelidas a revelar tais informação às autoridades ou a terceiro sob certas circunstâncias.<br/>
                        <strong>18.3.</strong> Nos casos em que terceiro possa interceptar ou acessar tais informações ou transmissões de dados (hacker), a Franqueadora não poderá ser responsabilizada pela divulgação dessas informações.<br/>
                        <strong>19.</strong> A Promotora reconhece que, em decorrência deste Termo, poderá ter acesso a informações da Franqueadora e Franqueada consideradas confidenciais, notadamente toda e qualquer informação que normalmente não é disponibilizada e que seria útil ou significativa para os competidores, incluindo, mas não se limitando a, segredos comerciais, processos, fórmulas, arquivos, know-how, melhoramentos, técnicas, plano de negócios, estratégias e informações sobre clientes e fornecedores (as “Informações Confidenciais”).<br/>
                        <strong>20.</strong> Durante o prazo de vigência deste Termo e por um período de 5 (cinco) anos após seu término, qualquer que seja o motivo do término, a Promotora compromete-se a não revelar, direta ou indiretamente, a qualquer pessoa física, jurídica ou entidade de qualquer natureza, as Informações Confidenciais às quais tenha tido acesso ou venha a ter acesso, por qualquer meio. <br/>
                        <strong>21.</strong> As Informações Confidenciais somente poderão ser reveladas pela Promotora caso expressamente autorizado pela Franqueadora, ou se as Informações Confidenciais se tornarem públicas sem a participação da Promotora, sob pena de caracterização de crime de concorrência desleal.<br/>
                        <strong>22.</strong> A Promotora informará imediatamente a Franqueadora e a Franqueada a respeito de qualquer tentativa não autorizada de terceiros de obter ou usar as Informações Confidenciais.<br/>
                        <strong>23.</strong> A Promotora reconhece que todas as Informações Confidenciais às quais tenha acesso em virtude deste Termo serão sempre de propriedade da Franqueadora. Por essa razão, desde já se compromete a devolver, sempre que solicitado e imediatamente, tais Informações Confidenciais, além de todos os outros documentos de propriedade da Franqueadora e Franqueada, tais como, mas não só, projetos, relatórios, manuais, correspondências e relações de clientes.<br/>
                        <strong>24.</strong> A Promotora reconhece que a divulgação, sob qualquer forma, das Informações Confidenciais para o mercado, concorrentes ou terceiros, durante ou após a extinção deste Termo, por qualquer motivo, tem o condão de causar graves prejuízos, imediatos e futuros, incluindo lucros cessantes, para a Franqueadora. <br/>
                        <strong>25.</strong> No caso de violação das obrigações de confidencialidade previstas no presente instrumento, as Partes ficam obrigadas a indenizar todos os prejuízos sofridos, incluindo lucros cessantes, sem prejuízo das demais cominações legais, desde que apurados judicialmente. <br/>

                        <strong>Da vigência</strong><br/>
                        <strong>26.</strong> A relação jurídica estabelecida por força deste Termo terá início na data em que o cadastro realizado pela Promotora for aprovado pela Franqueadora e vigorará por prazo indeterminado.<br/>

                        <strong>Da extinção</strong><br/>
                        <strong>27.</strong> Este Termo poderá ser denunciado unilateralmente por qualquer das Partes, por qualquer motivo e com um aviso prévio de 10 (dez dias) úteis, mediante comunicação por escrito, via abertura de chamado no sistema de suporte da Arezzo. <br/>
                        <strong>28.</strong> Este Termo poderá ser considerado rescindido pela Franqueadora, independente de notificação e com efeito imediato, nos seguintes casos: <br/>
                        <strong>a)</strong> Identificada qualquer violação legal e/ou às disposições destes Termos e Condições Gerais;<br/>
                        <strong>b)</strong> Identificada a prática de atos fraudulentos, seja de forma direta ou indireta pela Promotora;<br/>
                        <strong>c)</strong> Verificado qualquer dano direto ou indireto à Franqueadora, às suas filiadas, às empresas do seu grupo econômico, aos seus Usuários ou a quaisquer terceiros; e<br/>
                        <strong>d)</strong> Descumpridos quaisquer dos termos pactuados entre as partes neste instrumento.<br/>
                        <strong>29.</strong> Adicionalmente, a rescisão imediata deste instrumento pela Franqueadora na hipótese de infração da Promotora de quaisquer das Cláusulas ou disposição do presente Termo, poderá ensejar a comunicação posterior da rescisão e a Promotora arcará com uma multa compensatória equivalente ao valor das últimas 3 (três) bonificações recebidas, sem prejuízo de eventuais perdas e danos ou outras penalidades previstas neste Termo.<br/>
                        <strong>30.</strong> Também poderá ensejar a rescisão do presente Termo, sem a necessidade de notificação prévia e a incidência de quaisquer penalidades, a ocorrência de caso fortuito ou força maior conforme definição legal expressa no Código Civil Brasileiro, que impeçam a sua continuidade.<br/>
                        <strong>31.</strong> Independentemente da causa de encerramento do Termo haverá a imediata suspensão, para fins de Bonificação, da captação de novas vendas de produtos e/ou serviços através do link único da Promotora, permanecendo válidas aquelas que entrarem e forem contratadas até o período de vigência e ficando.<br/>
                        <strong>32.</strong> Em caso de término ou de qualquer forma de rescisão do Termo entre as Partes, a Franqueadora se reserva o direito de manter os dados e o histórico das transações realizadas durante a vigência da relação, que poderão ser arquivados por tempo indeterminado, garantido que poderá utilizar e dispor dessas informações como bem lhe aprouver.<br/>
                        <strong>32.1.</strong> É responsabilidade da Promotora remover todas as referências, os aplicativos/formatos e links que porventura tenha disponibilizado e/ou instalado em qualquer meio, inclusive no site de sua propriedade, em decorrência deste documento, independentemente de comunicação prévia<br/>
                        <strong>33.</strong> A Promotora reconhece neste ato que a rescisão deste Termo e a consequente remoção dos conteúdos ora estipulada não gera quaisquer direitos a perdas e danos, prejuízos, indenização, lucro cessante ou qualquer outra classe de ressarcimento.<br/>

                        <strong>Da propriedade intelectual</strong><br/>
                        <strong>34.</strong> A Franqueadora é exclusiva titular e detentora dos direitos sobre o design, código fonte do site e da plataforma de venda, bem como das marcas, logotipos, símbolos distintivos e identidade visual relacionados a plataforma e a sua marca, incluindo, mas não se limitando a, textos, softwares, scripts, imagens gráficas, fotos, sons, músicas, vídeos, recursos interativos e similares, marcas de serviços, "look and feel", entre outros, pertencendo de forma exclusiva à Franqueadora.<br/>
                        <strong>34.1.</strong> Os elementos, tecnologia e/ou ferramentas encontrados e utilizados na plataforma estão sujeitos aos direitos intelectuais de acordo com as leis brasileiras e tratados e convenções internacionais dos quais o Brasil é signatário.<br/>
                        <strong>34.2.</strong> As Partes estão cientes e concordam que a utilização, redistribuição, comercialização e/ou reprodução de conteúdo ou parte de conteúdo de propriedade da Franqueadora deverão seguir as normas concernentes às leis brasileiras de Direitos Autorais, sob pena de caracterização de utilização indevida e/ou infração a direitos intelectuais de terceiros.<br/>
                        <strong>34.3.</strong> A Promotora declara que é legitimo possuidora dos direitos para referentes as marcas e as imagens para fins de utilização no link único, bem como se compromete a não violar quaisquer direitos de terceiros, comprometendo-se desde já a responder integral e exclusivamente no caso de eventual violação.<br/>
                        <strong>34.4.</strong> Ao realizar o cadastro na Plataforma a Promotora concede, durante a vigência deste Termo, à Franqueadora e Franqueada uma licença e uma autorização gratuita para publicar, exibir, reproduzir, disponibilizar e comercializar as suas marcas e as suas eventuais imagens, bem como realizar campanhas publicitárias destes em conexão com o objeto deste instrumento.<br/>
                        <strong>34.5.</strong> As Partes acordam que, exceto conforme expressamente previsto neste instrumento, as Partes, direta ou indiretamente, não utilizarão ou autorizarão o uso das marcas, logotipos, jargões ou qualquer tipo de propriedade intelectual (incluindo direitos de propriedade industrial, tais como patentes, segredos de empresa, know how, processos e inovações, registráveis ou não) da outra Parte ou de suas controladas, controladoras, afiliadas ou licenciantes.<br/>
                        <strong>34.6.</strong> O presente Termo não outorga a qualquer uma das Partes qualquer direito sobre qualquer propriedade intelectual da outra que não aqueles expressamente previstos no presente Termo, devendo o uso de qualquer propriedade intelectual ocorrer de maneira que a Parte detentora dos direitos sobre a propriedade intelectual seja reconhecida sempre como sua única proprietária ou titular.<br/>
                        <strong>34.7.</strong> A Promotora concorda em isentar a Franqueadora e Franqueada, bem como quaisquer de seus colaboradores, sócios ou prepostos, de qualquer responsabilidade decorrente de eventual violação de direitos de propriedade intelectual decorrentes do cumprimento desse Termo.<br/>

                        <strong>Das disposições finais</strong><br/>
                        <strong>35.</strong> O não exercício por qualquer das Partes de quaisquer direitos ou faculdades que lhes sejam conferidos por este Termo ou pela Lei, bem como a eventual tolerância contra infrações contratuais cometidas pela outra Parte, não importará em renúncia a qualquer dos seus direitos contratuais ou legais, novação ou alteração de cláusulas deste Termo, podendo a Parte, a seu exclusivo critério, exercê-los a qualquer momento.<br/>
                        <strong>36.</strong> O presente Termo é firmado pelas Partes, que concordam expressamente com os termos aqui ajustados, obrigando-se mutuamente pelos direitos e obrigações decorrentes do mesmo, bem como, eventualmente, seus sucessores, a qualquer título.<br/>
                        <strong>37.</strong> Nenhuma Parte poderá ceder e, de nenhuma forma, transferir, total ou parcialmente o presente Termo, ou quaisquer direitos decorrentes deste, sem o consentimento por escrito da outra, ressalvados os casos de transferência resultante da reestruturação societária e outras formas de fusão, cisão ou incorporação de qualquer das Partes, ou, no caso de cessão ou transferência por parte da Franqueadora para qualquer uma das empresas do seu grupo econômico.<br/>
                        <strong>38.</strong> O presente Termo consolida toda e qualquer prévia negociação ou acordo, verbal ou por escrito, referente ao seu objeto, sobrepondo-se, portanto, a todos os entendimentos, negociações e conversas anteriores.<br/>
                        <strong>39.</strong> Caso qualquer das Partes, para a conservação de seus direitos contra a outra, venha recorrer à via judicial, poderá exigir, além dos valores pecuniários que lhe forem devidos nos termos deste Termo, todas as despesas judiciais a que tenha incorrido e, ainda, os honorários advocatícios, além de eventuais perdas e danos.<br/>
                        <strong>40.</strong> Se, em decorrência de qualquer decisão judicial irrecorrível, qualquer disposição ou termo deste Termo for sentenciada nula ou anulável, tal nulidade ou anulabilidade não afetará as demais cláusulas deste Termo, as quais permanecerão em pleno vigor, obrigando ambas as Partes.<br/>
                        <strong>41.</strong> Nenhuma das Partes responde pelos insucessos comerciais da outra e por reclamações de terceiros, clientes desta, exceto nos casos em que for comprovada a ação ou omissão deliberada a fim de prejudicar a outra (dolo).<br/>
                        <strong>42.</strong> As Partes deste Termo são independentes e a relação existente entre elas limita-se aos termos do objeto previsto neste instrumento, não representando qualquer outra forma de associação ou vínculo de ordem civil, securitário ou trabalhista entre as mesmas, seus sócios, diretores, empregados, prepostos, prestadores de serviços, fornecedores, colaboradores e/ou quaisquer outros agentes que com ela mantenha qualquer relação, seja da natureza que for.<br/>
                        <strong>42.1.</strong> As Partes são independentes. Nada neste Termo fará com que surja qualquer espécie de relação jurídica que não a expressamente pactuada, não cabendo às Partes efetuarem declarações em nome da outra, nem podendo celebrar qualquer negócio que envolva a outra, sem aprovação prévia e por escrita.<br/>
                        <strong>43.</strong> Nenhuma delas tem qualquer direito, poder ou autoridade de assumir qualquer obrigação ou responsabilidade em nome ou por conta da outra.<br/>
                        <strong>44.</strong> O Termo formalizado não vincula nenhuma das Partes com relação à outra quanto aos resultados econômicos presentes ou futuros de seus respectivos negócios, não sendo, pois, nenhuma delas responsável com relação à outra, por tais resultados, seja durante a vigência deste Termo ou mesmo após o seu término, a qualquer título.<br/>
                        <strong>45.</strong> A Promotora celebra o presente Termo em caráter não-exclusivo perante a Franqueadora.<br/>
                        <strong>46.</strong> As Partes concordam que o presente instrumento, incluindo todas as páginas e eventuais anexos, formado por meio digital, representa integralmente os termos acordados entre as Partes, conforme os artigos 107, 219 e 220 do Código Civil , e a eventual formalização do Termo de maneira eletrônica, admitida nesta cláusula, será suficiente para a validade, eficácia e integral vinculação das Partes às avenças nele contidas nos termos do art. 10, § 2º, da Medida Provisória n. 2.200-2/2001.<br/>

                        <strong>Do foro de eleição</strong><br/>
                        <strong>47.</strong> As Partes elegem o foro da Comarca de Belo Horizonte, MG, como competente para dirimir eventuais controvérsias oriundas desse Termo, com prejuízo de qualquer outro, por mais privilegiado que seja ou venha a ser.<br/>
                    </p>
                </div>
            </b-col>
        </b-row>
        <b-row class="rodape" style="padding-top: 20px">
            <b-col class="text-right">
                <b-img-lazy style="height: 20px"
                            alt="ZZVendas" fluid
                            :src=" require( '../../assets/logos/anacapri-branco.png') "
                ></b-img-lazy>
            </b-col>
            <b-col class="text-left d-flex align-items-center">
                <b-img-lazy style="height: 15px"
                            alt="ZZVendas" fluid
                            :src=" require( '../../assets/logos/arezzo-branco.png') "
                ></b-img-lazy>
            </b-col>
        </b-row>
        <b-row class="rodape" style="padding-bottom: 20px">
            <b-col class="text-center">
                Todos os direitos reservados Arezzo&Co
            </b-col>
        </b-row>
    </b-container>
</template>
<style lang="scss">
#termos-de-uso
{
    *
    {
        font-family: "Montserrat", Arial, Helvetica, sans-serif !important;
    }

    h1
    {
        color: #1E6B9C;
        font-family: "Nunito", Arial, Helvetica, sans-serif !important;
        line-height: 76px;
    }

    div#conteudo
    {
        background-color: white;
        border-radius: 50px;
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 20px;

        h2
        {
            text-align: center;
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: bold;
        }

    }

    .rodape
    {
        background-color: #1EBCD5;
        color: white;
        font-size: 10px;
    }
}
</style>
<script>

import {Utils} from "../../components/utils";

export default {
    name: 'termos-de-uso',
    mixins: [Utils],
    mounted() {
        document.title = 'Termos de uso';
        document.getElementsByTagName('body')[0].style.backgroundColor = '#e9e9e9';
    },
}
</script>